import { MouseEventHandler, ReactElement } from "react";
import { Link } from "react-router-dom";

export default function StyledButton(props: {
  text: string;
  title?: string;
  link?: string;
  onClick?: MouseEventHandler;
  disabled?: boolean;
  isSecondaryAction?: boolean;
}): ReactElement {
  const coreStyles: string = "py-2 px-6 rounded";
  const primaryStyles: string =
    "text-white bg-CostcoAction hover:bg-CostcoAction-hover";
  const secondaryStyles: string =
    "border-solid border-2 border-CostcoAction bg-white text-CostcoAction hover:bg-gray-170 hover:border-CostcoAction-hover hover:text-CostcoAction-hover";
  const disabledStyle: string = "bg-gray-650 text-white ";

  const renderButton = () => {
    const additionalStyles = props.isSecondaryAction
      ? secondaryStyles
      : primaryStyles;
    const buttonStyles: string = `${coreStyles} ${props.disabled ? disabledStyle : additionalStyles}`;
    return (
      <button
        type="button"
        title={props.title ?? ""}
        onClick={props.onClick}
        disabled={props.disabled}
        className={buttonStyles}
      >
        {props.text}
      </button>
    );
  };

  const render = () => {
    if (props.link) {
      return <Link to={props.link}>{renderButton()}</Link>;
    }

    return renderButton();
  };

  return render();
}
