import { Dispatch, ReactElement, SetStateAction } from "react";

export interface PageButtonProps {
  isNext: boolean;
  text: string;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

export default function PageButton(props: PageButtonProps): ReactElement {
  const render = () => {
    const { currentPage, setCurrentPage, isNext, text } = props;
    return (
      <button
        id={`documents-${isNext ? "next" : "previous"}`}
        type="button"
        onClick={() =>
          setCurrentPage(isNext ? currentPage + 1 : currentPage - 1)
        }
        className={`hidden md:flex items-center justify-center h-full cursor-pointer group focus:outline-none ${props.isNext ? "right-0" : "left-0"}`}
      >
        <span className="inline-flex items-center justify-center w-6 h-6 rounded-full group-hover:bg-gray-200/60 group-focus:ring-gray-500/70 group-focus:outline-none">
          <svg
            className="w-3 h-3 text-gray-600 group-hover:text-gray-800"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={isNext ? "m1 9 4-4-4-4" : "M5 1 1 5l4 4"}
            />
          </svg>
          <span className="sr-only">{text}</span>
        </span>
      </button>
    );
  };

  return render();
}
