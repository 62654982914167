import { ReactElement, useState } from "react";
import PageContent from "../../api/interface/PageContent";
import DocumentContent from "../content/DocumentContent";
import PageButton from "../common/PageButton";
import usePageData from "../../hooks/usePageData";
import ContentType from "../../api/interface/ContentType";

export default function DocumentsSection(): ReactElement {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const title: string = "Documents";
  const { content } = usePageData();

  const renderDocumentCount = (
    firstItem: number,
    lastItem: number,
    totalItems: number,
  ) => {
    if (totalItems === 0) {
      return (
        <div className="my-6 text-gray-900 font-normal">
          No documents have been published.
        </div>
      );
    }
    return (
      <>
        Documents <span className="font-semibold">{firstItem}</span> to{" "}
        <span className="font-semibold">{lastItem}</span> of{" "}
        <span className="font-semibold">{totalItems}</span>
      </>
    );
  };

  const renderPagination = (items: PageContent[]) => {
    const firstItem = 1 + (currentPage - 1) * 5;
    const totalItems = items?.length || 0;
    const lastItem = Math.min(firstItem + 4, totalItems);

    if (totalItems === 0) {
      return (
        <div className="flex mb-2">
          <div className="text-center flex-1 flex-grow text-gray-700 font-thin">
            {renderDocumentCount(firstItem, lastItem, totalItems)}
          </div>
        </div>
      );
    }

    return (
      <div className="flex mb-2">
        {currentPage > 1 && (
          <PageButton
            isNext={false}
            text="Previous Page"
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
        <div className="text-center flex-1 flex-grow text-gray-700 font-thin">
          {renderDocumentCount(firstItem, lastItem, totalItems)}
        </div>
        {lastItem !== totalItems && (
          <PageButton
            isNext
            text="Next Page"
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    );
  };

  const renderDocumentsList = (renderedItems: PageContent[]) => (
    <div className="py-4">
      {renderedItems.map((item) => (
        <DocumentContent item={item} key={item.name} />
      ))}
    </div>
  );

  const render = () => {
    let items = content.filter((item) => item.type === ContentType.DOCUMENTS);
    items = items.reverse();
    const firstItem = 1 + (currentPage - 1) * 5;
    const totalItems = items?.length || 0;
    const lastItem = Math.min(firstItem + 4, totalItems);
    const renderedItems: PageContent[] = [];
    if (items) {
      for (let i = firstItem - 1; i < lastItem; i += 1) {
        if (items[i]) {
          renderedItems.push(items[i]);
        }
      }
    }

    return (
      <div className="flex-1 border border-gray-300 bg-white px-4 py-2 shadow-md w-full">
        <div className="flex">
          <div className="text-xl text-blue-900 leading-8">{title}</div>
          <div className="flex-grow" />
          <a
            className="text-sm text-blue-900 leading-8 hover:underline hover:text-CostcoAction"
            href="/documents"
          >
            View all
          </a>
        </div>
        {totalItems > 0 && renderDocumentsList(renderedItems)}
        {renderPagination(items)}
      </div>
    );
  };

  return render();
}
