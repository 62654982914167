import Logger from "../common/Logger";

type FrontendAppSettings = {
  bff_base_url: string;
  openid_configuration: string;
  ping_client_id: string;
  cloud_environment: string;
  ecm_url: string;
  costco_environment: string;
  vam_url: string;
}

const DEFAULT_FRONTEND_CONFIG: FrontendAppSettings = {
  bff_base_url: "https://localhost:8443",
  openid_configuration: "http://localhost:7100/openid-configuration",
  ping_client_id: "none",
  cloud_environment: "np",
  ecm_url: "https://api-qat.np.ct-costco.com/corp/content-management/v1/file",
  costco_environment: "qat",
  vam_url: "https://api-adt.np.ct-costco.com/corp/userProfileVendor/v1"
}

export const DEFAULT_BFF_URL: string = DEFAULT_FRONTEND_CONFIG.bff_base_url;

export default class AppSettingsService {
  static settings: FrontendAppSettings | undefined;

  static async fetchAndSetAppSettings() {
    try {
      const response = await fetch("/appsettings.json");
      const appSettings = await response.json();
      AppSettingsService.populateAppSettings(appSettings);
      Logger.logInfo(
        `Response was ${appSettings} and app settings are ${AppSettingsService.settings}`,
      );
    } catch (error) {
      Logger.logError(`Failed to retrieve App Settings! ${error}`);
    }
  }

  private static populateAppSettings(appSettings: FrontendAppSettings) {
    const newSettings: FrontendAppSettings = {
      ...DEFAULT_FRONTEND_CONFIG,
    };

    newSettings.bff_base_url = appSettings.bff_base_url ?? DEFAULT_FRONTEND_CONFIG.bff_base_url;

    newSettings.openid_configuration = appSettings.openid_configuration ?? DEFAULT_FRONTEND_CONFIG.openid_configuration;

    newSettings.ping_client_id = appSettings.ping_client_id ?? DEFAULT_FRONTEND_CONFIG.ping_client_id;

    newSettings.cloud_environment = appSettings.cloud_environment ?? DEFAULT_FRONTEND_CONFIG.cloud_environment;

    newSettings.vam_url = appSettings.vam_url ?? DEFAULT_FRONTEND_CONFIG.vam_url;

    AppSettingsService.settings = newSettings;
  }

  static getBaseUrl(): string {
    return AppSettingsService.settings?.bff_base_url ?? DEFAULT_FRONTEND_CONFIG.bff_base_url;
  }

  static getOpenIdConfigUrl(): string {
    return AppSettingsService.settings?.openid_configuration ?? DEFAULT_FRONTEND_CONFIG.openid_configuration;
  }

  static getPingClientId(): string {
    return AppSettingsService.settings?.ping_client_id ?? DEFAULT_FRONTEND_CONFIG.ping_client_id;
  }

  static getECMUrl(): string {
    return AppSettingsService.settings?.ecm_url ?? DEFAULT_FRONTEND_CONFIG.ecm_url;
  }

  static getVAMUrl(): string {
    return AppSettingsService.settings?.vam_url ?? DEFAULT_FRONTEND_CONFIG.vam_url;
  }

  static getCostcoEnvironment(): string {
    return AppSettingsService.settings?.costco_environment ?? DEFAULT_FRONTEND_CONFIG.costco_environment;
  }

  static getCloudEnvironment(): string {
    return AppSettingsService.settings?.cloud_environment ?? DEFAULT_FRONTEND_CONFIG.cloud_environment;
  }
}
