import { useCallback, useEffect, useRef, useState } from "react";
import { ColDef, ColGroupDef, GridApi } from "ag-grid-community";
import { checkboxDefaultStyle } from "./CheckBox";
import StyledButton from "./StyledButton";

interface ColumnNode<T> {
  keys: string[];
  name: string;
  isChecked: boolean;
  children?: ColumnNode<T>[];
  colDef: ColDef<T> | ColGroupDef<T>;
}

const ColumnCustomizeFilter = <T,>({
  gridApi,
  onClickOutside,
}: {
  gridApi: GridApi<T>;
  onClickOutside: () => void;
}) => {
  const columnFilterRef = useRef<HTMLDivElement>(null); // Add this line

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        columnFilterRef.current &&
        !columnFilterRef.current.contains(event.target as Node)
      ) {
        onClickOutside();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClickOutside]);

  const buildColumnTree = useCallback(
    (colDefs?: (ColDef<T> | ColGroupDef<T>)[]): ColumnNode<T>[] => {
      if (!colDefs) return [];

      return colDefs.map((colDef) => {
        const keys: string[] = [];

        const name = colDef.headerName ?? "Unnamed Column";

        let children;
        let isChecked;
        const colChildren = (colDef as ColGroupDef<T>).children;

        if (colChildren) {
          children = buildColumnTree(colChildren);
          const childColIds = colChildren.flatMap(
            (childColDef) => (childColDef as ColDef<T>).colId!,
          );

          keys.push(...childColIds);
          // If no children are checked, then uncheck the parent
          isChecked = children.some((nodeChild) => nodeChild.isChecked);
        } else {
          keys.push((colDef as ColDef<T>).colId!);
          isChecked = !(colDef as ColDef<T>).hide;
        }

        const node: ColumnNode<T> = {
          keys,
          name,
          isChecked,
          colDef,
          children,
        };

        return node;
      });
    },
    [],
  );

  const [columnTree, setColumnTree] = useState<ColumnNode<T>[]>(
    buildColumnTree(gridApi.getColumnDefs()),
  );

  const updateColumnTree = (colIds: string[], isChecked: boolean) => {
    gridApi.setColumnsVisible(colIds, isChecked);
    const updatedTree = buildColumnTree(gridApi.getColumnDefs());
    setColumnTree(updatedTree);
  };

  const onCheckboxChange = (node: ColumnNode<T>, isChecked: boolean) => {
    updateColumnTree(node.keys, isChecked);
  };

  const setAllColumnsVisible = (nodes: ColumnNode<T>[], isChecked: boolean) => {
    const colIds = nodes.flatMap((node) => node.keys);

    updateColumnTree(colIds, isChecked);
  };

  const addSelectionButtons = (nodes: ColumnNode<T>[]) => {
    const hasUnchecked = nodes.some((node) => !node.isChecked);
    const buttonText = hasUnchecked ? "Select All" : "Select None";
    return (
      <div className="pb-1">
        <label className="items-center w-full ms-2 mt-[-3px] text-gray-900">
          <StyledButton
            text={buttonText}
            onClick={() => setAllColumnsVisible(nodes, hasUnchecked)}
          />
        </label>
      </div>
    );
  };

  const renderColumnTree = (nodes: ColumnNode<T>[], depth: number = 0) => {
    return (
      <div className="flex flex-col">
        {depth === 0 && addSelectionButtons(nodes)}
        {nodes.map((node) => (
          <div key={node.keys[0]} className={`ml-${depth * 6} pt-1/2`}>
            <label className="items-center w-full ms-2 mt-[-3px] text-gray-900">
              <input
                type="checkbox"
                className={checkboxDefaultStyle}
                checked={node.isChecked}
                onChange={(e) => onCheckboxChange(node, e.target.checked)}
              />
              {node.name}
            </label>
            {node.children && renderColumnTree(node.children, depth + 1)}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="absolute z-10" ref={columnFilterRef}>
      <div className="h-auto mt-2 p-2 pr-3 bg-gray-150 shadow-md shadow-gray/75">
        {renderColumnTree(columnTree)}
      </div>
    </div>
  );
};

export default ColumnCustomizeFilter;
